export const loaders = [
  "./loaders/1.svg",
  "./loaders/2.svg",
  "./loaders/3.svg",
  "./loaders/4.svg",
  "./loaders/5.svg",
  "./loaders/6.svg",
  "./loaders/7.svg",
  "./loaders/8.svg"
]
export const designprojects = [
  {
    id: 0,
    title: "Catarsis Catalina",
    description:
      "Brand identity for a local pottery artist. As an architect and a collage illustrator too, we managed to incorporate this versatility with an open color palette and a fun use of the brand.",
    image: "design%20projects/catarsis%20catalina/12.png",
    link: "https://www.behance.net/gallery/125763687/cata-collage-ceramics-arquitecture-identity",
    year: "2021",
    gallery: ["./design projects/catarsis catalina/1.gif",
              "./design projects/catarsis catalina/2.png",
              "./design projects/catarsis catalina/3.png",
              "./design projects/catarsis catalina/4.png",
              "./design projects/catarsis catalina/5.png",
              "./design projects/catarsis catalina/6.png",
              "./design projects/catarsis catalina/7.png",
              "./design projects/catarsis catalina/8.png",
              "./design projects/catarsis catalina/9.png",
              "./design projects/catarsis catalina/10.png",
              "./design projects/catarsis catalina/11.png",
              "./design projects/catarsis catalina/12.png"],
    coauthor: "La Perra Gorda"
  },
  {
    id: 1,
    title: "KOMA",
    description:
      "Brand identity and art direction for a multisensorial and multidimensional Chef at Home service. KOMA is a one moment experience, guided by Japanese contemporary fusion food.",
    image: "design%20projects/koma/5.png",
    link: "https://www.behance.net/gallery/137093435/KOMA-IGOZE",
    year: "2021",
    gallery: ["./design projects/koma/1.png",
              "./design projects/koma/2.png",
              "./design projects/koma/3.gif",
              "./design projects/koma/4.png",
              "./design projects/koma/5.png",
              "./design projects/koma/6.png",
              "./design projects/koma/7.png",
              "./design projects/koma/8.png",
              "./design projects/koma/9.png"],
    coauthor: "ONMI Design"
  },
  {
    id: 2,
    title: "AKHÁSICO",
    description:
      "Brand identity for a zen training center. It includes a whole storytelling of the space, along with the company values: courage, strength, temperance, determination and growth.",
    image: "design%20projects/akhasico/5.png",
    link: "",
    year: "2021",
    gallery: ["./design projects/akhasico/1.png",
              "./design projects/akhasico/2.png",
              "./design projects/akhasico/3.png",
              "./design projects/akhasico/4.png",
              "./design projects/akhasico/5.png",
              "./design projects/akhasico/6.png",
              "./design projects/akhasico/7.png",
              "./design projects/akhasico/8.png",
              "./design projects/akhasico/9.png",
              "./design projects/akhasico/10.png",
              "./design projects/akhasico/11.png",
              "./design projects/akhasico/12.png",
              "./design projects/akhasico/13.png",
              "./design projects/akhasico/14.png"],
    coauthor: "ONMI Design"
  },
  {
    id: 3,
    title: "IRONSWEAT",
    description:
      "Brand identity for a gym based on the body transformation. We used the heat and energy of the bodies in movement for the colors and storytelling of the company.",
    image: "design%20projects/ironsweat/10.png",
    link: "",
    year: "2021",
    gallery: ["./design projects/ironsweat/1.png",
              "./design projects/ironsweat/2.gif",
              "./design projects/ironsweat/3.png",
              "./design projects/ironsweat/4.png",
              "./design projects/ironsweat/5.gif",
              "./design projects/ironsweat/6.gif",
              "./design projects/ironsweat/7.png",
              "./design projects/ironsweat/8.png",
              "./design projects/ironsweat/9.gif",
              "./design projects/ironsweat/10.png",
              "./design projects/ironsweat/11.gif",
              "./design projects/ironsweat/12.png",
              "./design projects/ironsweat/13.png",
              "./design projects/ironsweat/14.png"],
    coauthor: "ONMI Design"
  },
  {
    id: 4,
    title: "CP8",
    description:
      "Brand identity for a restaurant in Cabo de Palos, Spain. CP8 is a contemporary and elegant seafood restaurant. We used this in contrast with some pictures of the tradition of the town of Cabo de Palos.",
    image: "design%20projects/cp8/5.gif",
    link: "",
    year: "2021",
    gallery: ["./design projects/cp8/1.gif",
              "./design projects/cp8/2.png",
              "./design projects/cp8/3.png",
              "./design projects/cp8/4.png",
              "./design projects/cp8/5.gif",
              "./design projects/cp8/6.png",
              "./design projects/cp8/7.gif",
              "./design projects/cp8/8.png",
              "./design projects/cp8/9.png"],
    coauthor: "ONMI Design"
  },
  {
    id: 5,
    title: "RPB",
    description:
      "Brand identity for a law firm specialised on sports and entertainment law. We used the concept of the visual rules of the sports, which are the lines, for the whole brand.",
    image: "design%20projects/rpb/1.gif",
    link: "https://www.behance.net/gallery/143925437/RPB-Sport-Legal",
    year: "2021",
    gallery: ["./design projects/rpb/1.gif",
              "./design projects/rpb/2.png",
              "./design projects/rpb/3.gif",
              "./design projects/rpb/4.png",
              "./design projects/rpb/5.png",
              "./design projects/rpb/6.png",
              "./design projects/rpb/7.png",
              "./design projects/rpb/8.png",
              "./design projects/rpb/9.png",
              "./design projects/rpb/10.png",
              "./design projects/rpb/11.png"],
    coauthor: "ONMI Design"
  }
]

export const webprojects = [
  {
    id: 0,
    title: "El Cielo",
    skills: ["Next.js", "Sanity CMS"],
    description:
      "Website for a director's duo. The concept was about displaying the visual movement of the frames with the user interaction through hovering. Collaboration with Susana Marlo from La Perra Gorda.",
    video: "./web projects/elcielo.mp4",
    link: "https://elcielo.ooo/",
    year: "2024"
  },
  {
    id: 1,
    title: "NOW Energy",
    skills: ["Wordpress"],
    description:
      "Website for a Solar Power company that I made while working at ONMI Design. It has multiple pages with lots of information and different target audiences: homes, small companies and industries.",
    video: "./web projects/now energy.mp4",
    link: "",
    year: "2021"
  },
  {
    id: 2,
    title: "Tribu",
    skills: ["Wordpress"],
    description:
      "Website for a gym I made while I was working at ONMI Design, a creative agency from Spain. It's a one page site with multiple svg animations and microinteractions with the user.",
    video: "./web projects/tribu.mp4",
    link: "https://tribu.center/",
    year: "2021"
  },
  {
    id: 3,
    title: "KOMA",
    skills: ["Wordpress"],
    description:
      "Website for a Chef at Home service that I made while working at ONMI Design. It's a one page with lots of photos, scrolling effects and a reservation calendar.",
    video: "./web projects/koma.mp4",
    link: "",
    year: "2021"
  },
  {
    id: 4,
    title: "Cuchillo Amigo",
    skills: ["Wordpress"],
    description:
      "Final project for my two years Interactive Media Degree in Spain. It's a vegan recipes blog with entries, categories and photos. It's the first website I've built.",
    video: "./web projects/cuchillo amigo.mp4",
    link: "https://cuchilloamigo.com/",
    year: "2020"
  },
  {
    id: 5,
    title: "2022 Portfolio",
    skills: ["React", "GSAP"],
    description:
      "My first webapp in React. It hasn't changed a lot. It has two galleries of projects, scroll effects with GSAP Scroll Trigger and lots of microinteractions.",
    video: "./web projects/dev portfolio.mp4",
    link: "https://2022.mariorzgz.me/",
    year: "2022"
  },
];
